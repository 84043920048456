export const rowTitles = [
  'time',
  'Picks Just for You',
  'Pick Up Where You Left Off',
  'Your Playlists',
  'Chill Party',
  'Time to Chill',
  'Party for Chillin',
  'Chill',
  'Party',
  'Party Chill',
  'Chillin Time',
  'Future Favorites',
  'Currently Trending',
  'Popular in Your Location',
  'Old Favorites',
  'Chillin Party',
  'What Your Cool Friends Are Listening To',
  'Time To Concentrate (On Chillin)',
  "90's Favs",
  'Feel Good Picks',
  'Timeless Classics'
];
